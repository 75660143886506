import React, { useState } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import styled from 'styled-components';
import { handleLogin } from '../services/auth';
import { Spinner } from './spinner';
import { Link } from 'gatsby';

const FormLayout = styled.div`
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  margin-bottom: 30px;
`;

const Fieldset = styled.fieldset`
  background-color: #ffffff;
  border: 0;
  box-shadow: 0 2px 3px 0 rgba(61, 60, 58, 0.15);
  margin: 0;
  padding: 20px;

  legend {
    background-color: #ffffff;
    border-radius: 5px;
    font-weight: 700;
    padding: 5px 20px;
  }
`;

const TwoColumn = styled.div`
  display: grid;
  gap: 0 30px;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
`;

const StyledMessageSmall = styled.small`
  color: ${({ error }) => (error ? 'var(--c-error)' : 'var(--c-gray)')};

  strong {
    color: var(--c-black);
    font-size: 16px;
  }
`;

const Register = () => {
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    artistId: '',
    artistUrl: '',
    password: '',
  });
  const [errors, setErrors] = useState({
    artistId: {
      error: false,
      message: 'Sorry, that is not a valid Artist ID.',
    },
    artistUrl: {
      error: false,
      message: 'Verify your Artist URL.',
    },
    email: {
      error: false,
      message: 'Sorry, that email is already taken.',
    },
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setForm({ ...form, [name]: value });
  };

  // const checkArtistIdExists = () => {
  //   const artistId = Number(form.artistId);

  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`${process.env.GATSBY_MASKCARA_API}?customer_id=${artistId}`)
  //       .then((response) => {
  //         const result = response.data;
  //         resolve(result);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    // const result = await checkArtistIdExists();

    // If we can't find artist ID, bail.
    // if (!result[0]) {
    //   setErrors({ ...errors, artistId: { ...errors.artistId, error: true } });
    //   setLoading(false);
    //   return;
    // }

    // Reset errors.
    setErrors({
      ...errors,
      artistId: { ...errors.artistId, error: false },
      email: { ...errors.email, error: false },
      artistUrl: { ...errors.artistUrl, error: false },
    });

    axios
      .post(`${process.env.GATSBY_ADMIN_URL}/auth/local/register`, {
        first_name: form.firstName, // eslint-disable-line camelcase
        last_name: form.lastName, // eslint-disable-line camelcase
        username: form.firstName.toLowerCase() + Date.now(),
        password: form.password,
        email: form.email,
        artist_id: form.artistId, // eslint-disable-line camelcase
        // artist_url: result[0].web_alias, // eslint-disable-line camelcase
        artist_url: form.artistUrl, // eslint-disable-line camelcase
        blocked: false,
      })
      .then(() => {
        handleLogin({ email: form.email, password: form.password }, errors, setErrors);
      })
      .catch((error) => {
        if (error?.response?.data?.data?.[0]?.messages?.[0]?.id === 'Auth.form.error.email.taken') {
          setErrors({ ...errors, email: { ...errors.email, error: true } });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit} className="h-spacing-bottom">
      <FormLayout>
        <Fieldset>
          <legend>Account Info</legend>
          <TwoColumn>
            <label>
              First Name
              <input
                type="text"
                name="firstName"
                value={form.firstName}
                onChange={handleInputChange}
                required
              />
            </label>

            <label className="h-spacing-bottom">
              Last Name
              <input
                type="text"
                name="lastName"
                value={form.lastName}
                onChange={handleInputChange}
                required
              />
            </label>
          </TwoColumn>

          <label className={classNames({ 'h-error': errors.artistId.error })}>
            Artist ID
            <input
              type="text"
              name="artistId"
              value={form.artistId}
              onChange={handleInputChange}
              required
              pattern="\d*"
            />
            <StyledMessageSmall error={errors.artistId.error}>
              {errors.artistId.error ? (
                errors.artistId.message
              ) : (
                <>
                  Example: <strong>1029</strong>
                </>
              )}
            </StyledMessageSmall>
          </label>

          <label className={classNames({ 'h-error': errors.artistUrl.error })}>
            Artist URL
            <input
              type="text"
              name="artistUrl"
              value={form.artistUrl}
              onChange={handleInputChange}
              required
            />
            <StyledMessageSmall error={errors.artistUrl.error}>
              {errors.artistUrl.error ? (
                errors.artistUrl.message
              ) : (
                <>
                  Verify your URL:{' '}
                  <a
                    href={`https://${form.artistUrl}.seintofficial.com/`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >{`https://${form.artistUrl}.seintofficial.com/`}</a>
                </>
              )}
            </StyledMessageSmall>
          </label>
        </Fieldset>

        <Fieldset>
          <legend>Create Login</legend>
          <label className={classNames({ 'h-error': errors.email.error })}>
            Email
            <input
              type="email"
              name="email"
              value={form.email}
              onChange={handleInputChange}
              required
            />
            {errors.email.error && (
              <StyledMessageSmall error={errors.email.error}>
                {errors.email.message} <Link to="/forgot-password">Forgot Password?</Link>
              </StyledMessageSmall>
            )}
          </label>
          <label>
            Password
            <input
              type="password"
              name="password"
              value={form.password}
              onChange={handleInputChange}
              required
            />
          </label>

          <button className="button" type="submit">
            Sign Up
          </button>
        </Fieldset>
      </FormLayout>
      <Spinner loading={loading} />
    </form>
  );
};

export default Register;
