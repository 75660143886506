import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Row from '../components/row/row';
import Register from '../components/register';
import styled from 'styled-components';

const PageA = styled.a`
  .wysiwyg-content & {
    text-decoration: underline;
  }
`;

const JoinTeam = () => {
  return (
    <Layout>
      <SEO title="Join Team" />
      <Row className="wysiwyg-content">
        <h1>Join Team</h1>
        <p>
          Before you can join the team, you need to{' '}
          <PageA href="https://www.jointeamjacob.com/" target="_blank" rel="noreferrer noopener">
            become a seint
          </PageA>
          .
        </p>

        <Register />

        <p>
          Have a question or need help?{' '}
          <PageA href="mailto:team@jacobuniversity.com">Contact us!</PageA>
        </p>
      </Row>
    </Layout>
  );
};

export default JoinTeam;
